@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  src: url("../public/fonts/webfonts/FUTSALSPEED\ 3.otf") format("truetype");
  font-family: "FUTSALSPEED";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
}

@font-face {
  src: url("../public/fonts/webfonts/OpenSans-Light.ttf") format("truetype");
  font-family: "OpenSans";
  font-style: normal;
  font-display: swap;
  font-weight: 300;
}

@font-face {
  src: url("../public/fonts/webfonts/OpenSans-Regular.ttf") format("truetype");
  font-family: "OpenSans";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
}

.custom-otp-input {
  border-radius: 12px;
  color: #d4406d;
}

@font-face {
  src: url("../public/fonts/webfonts/Italiana-Regular.ttf") format("truetype");
  font-family: "Italiana", sans-serif;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  src: url("../public/fonts/webfonts/OpenSans-Medium.ttf") format("truetype");
  font-family: "OpenSans";
  font-style: normal;
  font-display: swap;
  font-weight: 500;
}

@font-face {
  src: url("../public/fonts/webfonts/OpenSans-SemiBold.ttf") format("truetype");
  font-family: "OpenSans";
  font-style: normal;
  font-display: swap;
  font-weight: 600;
}

@font-face {
  src: url("../public/fonts/webfonts/OpenSans-Bold.ttf") format("truetype");
  font-family: "OpenSans";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
}

@font-face {
  src: url("../public/fonts/webfonts/OpenSans-ExtraBold.ttf") format("truetype");
  font-family: "OpenSans";
  font-style: normal;
  font-display: swap;
  font-weight: 800;
}

@font-face {
  src: url("../public/fonts/webfonts/PlaywriteNZ-ExtraLight.ttf") format("truetype");
  font-family: "PlaywriteNZ";
  font-style: normal;
  font-display: swap;
  font-weight: 200;
}

@font-face {
  src: url("../public/fonts/webfonts/PlaywriteNZ-Light.ttf") format("truetype");
  font-family: "PlaywriteNZ";
  font-style: normal;
  font-display: swap;
  font-weight: 300;
}

@font-face {
  src: url("../public/fonts/webfonts/PlaywriteNZ-Regular.ttf") format("truetype");
  font-family: "PlaywriteNZ";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
}

@font-face {
  src: url("../public/fonts/webfonts/PlaywriteNZ-Thin.ttf") format("truetype");
  font-family: "PlaywriteNZ";
  font-style: normal;
  font-display: swap;
  font-weight: 100;
}


:root {
  --bold: #2b3f5a !important;
  --black: #2b3f5a !important;
  --brown: #6e7892 !important;
  --blue: #235dff !important;
  --yellow: #ffe984 !important;
  --light-brown: #f9f6f3 !important;
  --brown-light: rgba(110, 120, 146, 0.5) !important;
  --white: #ffffff !important;
  --red: #ff5749 !important;
  --green: #46cb5c !important;
  --toastify-color-info: #5254f1;
  --toastify-color-success: #46cb5c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #ff5749;
}

* {
  box-sizing: border-box;
  user-select: text;
  font-family: "Italiana", sans-serif !important;
}

html {
  scroll-behavior: smooth;
}

body {
  background: #fcfcfc !important;
  overflow-y: scroll;
  /* font-weight: 400 !important; */
  margin: 0;
}

/* .imgScale11 {
  @apply group-hover:scale-[1.1] group-hover:rotate-2 transition-all ease-in-out duration-500;
} */

body,
button,
select,
input,
textarea {
  font-family: "Italiana", sans-serif !important;
}

::selection {
  background: var(--yellow);
  color: var(--black);
}

::-webkit-scrollbar {
  width: 7px;
  height: 0;
}

::-webkit-scrollbar-track {
  background: var(--white);
}

::-webkit-scrollbar-thumb {
  background: var(--blue);
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

a {
  text-decoration: none !important;
  cursor: pointer;
  /* color: var(--black) !important; */
}

/* a:hover,
a.active {
  color: var(--blue) !important;
} */
ul {
  list-style-type: none !important;
  padding: 0;
}

input {
  outline: none !important;
  box-shadow: none !important;
  font-family: "Italiana", sans-serif;
}

.italiana {
  font-family: "Italiana", sans-serif !important;

}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  appearance: textfield;
}

input[type="date"] {
  cursor: text !important;
}

textarea {
  resize: none !important;
}

button {
  outline: none !important;
  box-shadow: none !important;
  cursor: pointer;
}

.container_main {
  width: 100% !important;
  box-sizing: border-box !important;
  padding: 0 calc(50% - 678px) !important;
}

@media only screen and (max-width: 1400px) {
  .container_main {
    padding: 0 10px !important;
  }
}

.first_slide {
  position: relative;
}

.first_slide::before,
.first_slide::after {
  content: "";
  position: absolute;
  background-size: cover;
  z-index: 1;
  transition: 1s ease-out;
  background-position: left top;
}

.first_slide::after {
  bottom: 0;
  height: 100%;
  width: 305px;
}

.first_slide::before {
  left: 75px;
  bottom: 75px;
  width: 124px;
  height: 116px;
}

.first_slide::before {
  bottom: 0;
  background-image: url("../public/images/slide/Vector-left.png");
  background-position: right top;
}

.first_slide::after {
  right: 350px;
  background-image: url("../public/images/slide/spicy-green.png");
  background-position: left bottom;
}

@media (max-width: 1800px) {
  .first_slide::after {
    width: 305px;
  }

  .first_slide:before {
    width: 124px;
  }
}

@media (max-width: 1400px) {
  .first_slide::after {
    width: 305px;
    right: 260px;
  }

  .first_slide::before {
    width: 124px;
  }
}

@media (max-width: 1200px) {
  .first_slide::after {
    width: 305px;
    right: 260px;
  }

  .first_slide::before {
    width: 120px;
  }
}

@media (max-width: 1050px) {
  .first_slide::after {
    width: 305px;
    right: 220px;
  }

  .first_slide::before {
    width: 120px;
  }
}

@media (max-width: 980px) {
  .first_slide::after {
    width: 305px;
    right: -100px;
  }

  .first_slide::before {
    width: 100px;
  }
}

@media (max-width: 620px) {
  .first_slide::after {
    top: 80px;
    right: 80px;
    width: 100px;
    height: 200px;
    transform: rotate(-210deg);
  }
}

@media (max-width: 500px) {
  .first_slide::before {
    width: 80px;
    height: 80px;
  }
}

@media (max-width: 400px) {
  .first_slide::after {
    top: 80px;
    right: 45px;
    width: 70px;
    height: 140px;
    transform: rotate(-210deg);
  }
}

.first_slide_text {
  opacity: 0;
  transition: opacity 3s ease-out;
}

.first_slide .first_slide_text {
  opacity: 1;
}

.first_slide_body::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 485px;
  background-size: cover;
  z-index: 0;
  transition: 1s ease-out;
  background-image: url("../public/images/slide/AFC\ FUTSAL\ ASIAN\ CUP\ 2022\ -\ Match\ \(3\)\ \(1\)\ 1.png");
  background-position: left;
}

.first_slide.expand_width .first_slide_body::after {
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 485px;
}

@media (max-width: 1800px) {
  .first_slide_body::after {
    width: 485px;
  }

  .first_slide.expand_width .first_slide_body::after {
    height: 100%;
  }
}

@media (max-width: 1400px) {
  .first_slide_body::after {
    width: 400px;
    height: 100%;
  }

  .first_slide.expand_width .first_slide_body::after {
    width: 400px;
    height: 100%;
  }
}

@media (max-width: 1290px) {
  .first_slide_body::after {
    width: 450px;
    height: 100%;
  }
}

@media (max-width: 1150px) {
  .first_slide_body::after {
    width: 450px;
  }

  .first_slide.expand_width .first_slide_body::after {
    height: 100%;
  }
}

@media (max-width: 1050px) {
  .first_slide_body::after {
    width: 400px;
  }

  .first_slide.expand_width .first_slide_body::after {
    height: 100%;
    width: 350px;
  }
}

@media (max-width: 980px) {
  .first_slide_body::after {
    display: none;
  }
}

.first_slide_body::before {
  content: "";
  position: absolute;
  height: 56px;
  width: 57px;
  background-size: cover;
  z-index: 0;
  transition: top 1s ease-out, left 1s ease-out, opacity 1s ease-out;
  top: 270px;
  left: calc(50% - 750px);
  opacity: 1;
  background-image: url("../public/images/slide/Vector.png");
  background-position: left top;
  animation: rotate 5s linear infinite;
  /* Updated to use the rotate animation */
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.first_slide.expand_width .first_slide_body::before {
  top: 270px;
  left: calc(50% - 750px);
  opacity: 1;
}

@media (max-width: 1500px) {
  .first_slide.expand_width .first_slide_body::before {
    bottom: 50px;
    left: calc(50% - 500px);
  }
}

@media (max-width: 1000px) {
  .first_slide.expand_width .first_slide_body::before {
    bottom: 50px;
    left: calc(50% - 350px);
  }
}

@media (max-width: 750px) {
  .first_slide.expand_width .first_slide_body::before {
    bottom: 50px;
    left: 100px;
  }
}

@media (max-width: 500px) {
  .first_slide.expand_width .first_slide_body::before {
    display: none;
  }
}

/* kk */
.for_spicy::after {
  content: "";
  position: absolute;
  top: 0;
  right: 560px;
  height: 252px;
  width: 244px;
  background-size: cover;
  z-index: 1;
  transition: height 1s ease-out;
  background-image: url("../public/images/slide/spicy-right.png");
  background-position: center;
}

.first_slide.expand_width .for_spicy::after {
  /* right: 560px;
  height: 252px;
  width: 244px; */
}

@media (max-width: 1800px) {
  .for_spicy::after {
    width: 244px;
  }

  .first_slide.expand_width .for_spicy::after {
    height: 252px;
  }
}

@media (max-width: 1400px) {
  .for_spicy::after {
    width: 224;
  }

  .first_slide.expand_width .for_spicy::after {
    right: 460px;
    height: 252px;
  }
}

@media (max-width: 1290px) {
  .for_spicy::after {
    display: none;
  }
}

.for_spicy::before {
  content: "";
  position: absolute;
  right: 15px;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 485px;
  background-size: cover;
  z-index: 2;
  transition: 1s ease-out;
  background-image: url("../public/images/slide/avatar.png");
  background-position: left;
}

.first_slide.expand_width .for_spicy::before {}

@media (max-width: 1400px) {
  .for_spicy::before {
    right: 15px;
    width: 400px;
  }
}

@media (max-width: 1290px) {
  .for_spicy::before {
    width: 400px;
  }
}

@media (max-width: 1150px) {
  .for_spicy::before {
    width: 400px;
  }
}

@media (max-width: 1050px) {
  .for_spicy::before {
    right: -35px;
    width: 400px;
  }
}

@media (max-width: 980px) {
  .for_spicy::before {
    display: none;
  }
}

/* kk */

/* .for_spicy::after {
  content: "";
  position: absolute;
  top: 0;
  height: 0;
  width: 270px;
  background-size: cover;
  z-index: 1;
  transition: width 1s ease-out;
  bottom: 0;
  background-image: url("../public/images/slide/spicy-right.png");
  background-position: left bottom;
}


.for_spicy.expand_width::after {
  right: 550px;
  height: 242px;
  width: 574px;
} */

/* ************************** */
.card_main {
  width: 100%;
  margin: 0;
  font-family: Verdana, sans-serif;
  color: white;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  min-height: 100vh;
  gap: 10px;
  flex-wrap: wrap;
}

.blob {
  filter: blur(40px);
  position: absolute;
  z-index: -1;
  top: 0;
  opacity: 0;
  left: 0;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  background: rgb(255, 255, 255, 0.5);
  transition: all 300ms ease-in-out;
}

.fakeblob {
  display: hidden;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 200px;
  height: 200px;
  border-radius: 50%;
}

.card {
  overflow: hidden;
  padding: 3px;
  margin: 0;
  background: rgb(128 128 128 / 0.2);
  border-radius: 10px;
  position: relative;
  transition: all 300ms ease-in-out;
}

.inner {
  border-radius: 7px;
  padding: 10px;
  width: 100%;
  height: 150px;
  background: rgb(26, 26, 26);
  backdrop-filter: blur(80px);
  transition: all 300ms ease-in-out;
}

.card:hover>.inner {
  background: rgb(26, 26, 26, 0.6);
  backdrop-filter: blur(80px);
}

/* ************************** */

.blureffect {
  box-sizing: border-box;
  border: 1px solid rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(15px);
  background: rgba(37, 117, 252, 0.1);
}

.blureffect2 {
  box-sizing: border-box;
  backdrop-filter: blur(65px);
  background: rgba(37, 117, 252, 0.3);
}

.blureffectError {
  box-sizing: border-box;
  border: 1px solid #f545004d;
  backdrop-filter: blur(15px);
  background: #f545001a;
}

.seat_drag_scroll {
  cursor: grab;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  user-select: none;
}

.errorSelect>div {
  box-sizing: border-box;
  border: 1px solid #f545004d;
  backdrop-filter: blur(15px);
  background: #f545001a !important;
  color: red !important;
}

/* input:-internal-autofill-selected {
  appearance: menulist-button;
  background-image: none !important;
  background-color: transparent !important;
  color: fieldtext !important;
} */

/* Targeting Chrome, Safari, and Edge */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  border: 1px solid #525f77;
  -webkit-box-shadow: 0 0 0 30px #081d45 inset !important;
  /* Change 'green' to the background color of your input */
  -webkit-text-fill-color: #f3f3f4 !important;
  /* Change '#000' to your desired text color */
}

/* Targeting Firefox */
input:-moz-autofill {
  border: 1px solid #525f77;
  box-shadow: 0 0 0px 1000px #081d45 inset !important;
  /* Change 'green' to the background color of your input */
  -moz-text-fill-color: #f3f3f4 !important;
  /* Change '#000' to your desired text color */
}

/* General styles for the input */
input {
  border: 1px solid #525f77;
  background-color: transparent;
  /* Ensure the input background is transparent */
  color: #f3f3f4;
  /* Default text color */
}

.hovered-text {
  color: white;
}

.hovered-img {
  filter: brightness(0) invert(1);
}

.rmdp-container {
  width: 100%;
  background-color: transparent;
}

.rmdp-wrapper {
  left: 0;
  background-color: #050f37;
  color: #fff;
  border-radius: 12px;
  margin-left: 10px;
  margin-right: 10px;
}

.rmdp-header-values,
.rmdp-day {
  color: #ffffff;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover {
  font-size: inherit;
  color: #000000 !important;
}

:where(.css-dev-only-do-not-override-m4timi).ant-carousel .slick-prev::after,
:where(.css-dev-only-do-not-override-m4timi).ant-carousel .slick-next::after {
  width: 30px;
  height: 30px;
  inset-inline-start: -20.658px;
}

:where(.css-dev-only-do-not-override-m4timi).ant-carousel .slick-next::after {
  margin-right: 30px !important;
  padding-right: 30px !important;
}

.ant-carousel .slick-prev::after {
  margin-left: 30px !important;
}

.names {
  font-family: "PlaywriteNZ", sans-serif !important;

}

@keyframes heart-float {
  0% {
    transform: translateY(100vh);
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    transform: translateY(-100vh);
    opacity: 0;
  }
}

.heart {
  position: absolute;
  bottom: -50px;
  width: 20px;
  height: 20px;
  background: url('/public/images/heart.png') no-repeat center center / contain;
  animation: heart-float 5s linear infinite;
}

.heart:nth-child(1) {
  left: 10%;
  animation-duration: 16s;
  animation-delay: 0s;
}

.heart:nth-child(2) {
  left: 30%;
  animation-duration: 15s;
  animation-delay: 5s;
}

.heart:nth-child(3) {
  left: 50%;
  animation-duration: 17s;
  animation-delay: 6s;
}

.heart:nth-child(4) {
  left: 70%;
  animation-duration: 14s;
  animation-delay: 1 s;
}

.heart:nth-child(5) {
  left: 90%;
  animation-duration: 16.5s;
  animation-delay: 14s;
}

.heart:nth-child(6) {
  left: 15%;
  animation-duration: 16.5s;
  animation-delay: 12s;
}

.heart:nth-child(7) {
  left: 75%;
  animation-duration: 18s;
  animation-delay: 1.5s;
}

.ant-modal-content {
  background-color: rgba(21, 85, 25, 0.8) !important;
}

.card {
  width: 45ch;
  height: 45ch;
  background: grey;
  border-radius: 5px;
  background-image: url(https://drscdn.500px.org/photo/435236/q%3D80_m%3D1500/v2?webp=true&sig=67031bdff6f582f3e027311e2074be452203ab637c0bd21d89128844becf8e40);
  background-size: cover;
  background-position: center center;
  box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.5s;
  will-change: transform;
  border: 15px solid white;
}

.card:hover {
  box-shadow: 0px 30px 100px -10px rgba(0, 0, 0, 0.4);
}

.rotating-container {
  position: relative;
  width: 120px;
  /* Adjust as needed */
  height: 120px;
  /* Adjust as needed */
  display: flex;
  justify-content: center;
  align-items: center;
}

.rotating-container img {
  position: absolute;
  z-index: 1;
}

.circle-text {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: rotate 10s linear infinite;
}

.circle-text::before {
  content: "Taklifnoma yaratish Taklifnoma yaratish ";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  white-space: nowrap;
  text-align: center;
  transform: rotate(0deg);
  transform-origin: center;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#name::placeholder,
#wish::placeholder {
  color: white !important;
}

.card-dashboard {
  position: relative;
  background: url('../public/images/slide/design1.jpg') no-repeat center center;
  background-size: cover;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

/* .card-dashboard::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(2px);
  z-index: 0;
}

.card-dashboard > div, .card-dashboard > a {
  position: relative;
  z-index: 1;
} */
.insta-profile {

  background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
}

.insta {
  background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}